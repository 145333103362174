import React from "react"
import Metadata from "../components/Metadata/Metadata"
import { graphql } from "gatsby"
import LayoutAbout from "../layout/LayoutAbout"
import Heading from "../components/Heading/Heading"
import Aside from "../components/Aside/AsideAbout"
import { frontmatterPathBuilder } from "../helpers/pathBuilders"

export const query = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { pathDirectory: { eq: $path } }) {
            html
            frontmatter {
                title
                description
            }
        }
    }
`

export default ({ data }) => {
    const page = data.markdownRemark
    const Main = () => <div dangerouslySetInnerHTML={{ __html: page.html }} />

    return (
        <>
            <Metadata title="About" />
            <LayoutAbout
                heading={
                    <Heading
                        headingLevel="1"
                        headingTitle={frontmatterPathBuilder(page).title}
                        headingDescription={
                            frontmatterPathBuilder(page).description
                        }
                    />
                }
                main={<Main />}
                aside={<Aside />}
            />
        </>
    )
}
