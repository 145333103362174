export function pagePathBuilder(prefix) {
    const pathPartial = prefix.frontmatter

    return (
        (pathPartial.pathDirectory ? pathPartial.pathDirectory : "") +
        (pathPartial.pathName ? pathPartial.pathName : "")
    )
}

export function frontmatterPathBuilder(prefix) {
    let path

    if (typeof prefix.frontmatter !== "undefined") {
        path = prefix.frontmatter ? prefix.frontmatter : ""
    }

    return path
}
